import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  userEmail: string;

  constructor(private auth: AuthService, private router: Router) { }

  ngOnInit() { }

  getUserEmail() {
    return this.auth.getUser().email;
  }

  logout() {
    this.auth.logout()
      .then(_ => this.router.navigate(['/login']));

    return false;
  }
}
