import { Injectable } from '@angular/core';
import { ApiClientService } from '../api-client.service';
import { Review } from './review.model';

@Injectable({
  providedIn: 'root'
})
export class ReviewsService {
  constructor(private apiClient: ApiClientService) { }

  post(newReview: Review) {
    return this.apiClient.post<Review>('reviews', newReview);
  }

  delete(id: number) {
    return this.apiClient.delete('reviews', id);
  }
}
