import { Injectable } from '@angular/core';
import { Restaurant } from './restaurant.model';
import { ApiClientService } from '../api-client.service';

@Injectable({
  providedIn: 'root'
})
export class RestaurantsService {

  constructor(private apiClient: ApiClientService) { }

  delete(id: number) {
    return this.apiClient.delete('restaurants', id);
  }

  get(id: number) {
    return this.apiClient.get<Restaurant>('restaurants', id);
  }

  getAll() {
    return this.apiClient.getAll<Restaurant[]>('restaurants');
  }

  post(newRestaurant: Restaurant) {
    return this.apiClient.post<Restaurant>('restaurants', newRestaurant);
  }

  put(updatedRestaurant: Restaurant) {
    return this.apiClient.put<Restaurant>('restaurants', updatedRestaurant, updatedRestaurant.id);
  }
}
