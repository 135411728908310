import { Component, OnInit, ViewChild } from '@angular/core';
import { User } from '../users/user.model';
import { UsersService } from '../users/users.service';
import { NgForm } from '@angular/forms';
import { UserType } from '../users/user-type';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  @ViewChild('registerForm') registerForm: NgForm;
  @ViewChild('errorModal') errorModal;
  errorMessage: string;
  newUser: User;

  constructor(private usersService: UsersService, private router: Router, private modalService: NgbModal) { }

  ngOnInit() {
    this.newUser = {
      email: '',
      password: '',
      user_type_id: UserType.REGULAR
    };
  }

  onSubmit() {
    if (this.registerForm.valid) {
      this.usersService.post(this.newUser)
        .subscribe(
          _ => this.router.navigate(['/login']),
          error => {
            this.errorMessage = error.error.message;
            this.modalService.open(this.errorModal);
        });
    }
  }

}
