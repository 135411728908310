import { Component, OnInit, ViewChild } from '@angular/core';
import { LoginCredentials } from './login-credentials.model';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @ViewChild('errorModal') errorModal;
  @ViewChild('loginForm') loginForm: NgForm;
  loginCredentials: LoginCredentials;
  errorMessage: string;

  constructor(private auth: AuthService, private router: Router, private modalService: NgbModal) {
    this.loginCredentials = {
      email: '',
      password: ''
    };
  }

  ngOnInit() {
  }

  onSubmit() {
    if (this.loginForm.valid) {
      this.auth.login(this.loginCredentials)
        .then(_ => this.router.navigate(['/']))
        .catch(error => {
          this.errorMessage = error;
          this.modalService.open(this.errorModal);
        });
    }
  }

}
