import { Injectable } from '@angular/core';
import { User } from './user.model';
import { ApiClientService } from '../api-client.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private apiClient: ApiClientService) { }

  delete(id: number) {
    return this.apiClient.delete('users', id);
  }

  get(id: number) {
    return this.apiClient.get<User>('users', id);
  }

  getAll() {
    return this.apiClient.getAll<User[]>('users');
  }

  post(newUser: User) {
    return this.apiClient.post<User>('users', newUser);
  }

  put(updatedUser: User) {
    return this.apiClient.put<User>('users', updatedUser, updatedUser.id);
  }
}
