import { Component, OnInit } from '@angular/core';
import { RestaurantsService } from '../restaurants/restaurants.service';
import { ReviewsService } from '../restaurants/reviews.service';
import { Restaurant } from '../restaurants/restaurant.model';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { Review } from '../restaurants/review.model';

@Component({
  selector: 'app-restaurant',
  templateUrl: './restaurant.component.html',
  styleUrls: ['./restaurant.component.css']
})
export class RestaurantComponent implements OnInit {
  restaurant: Restaurant;
  restaurantId: number;
  topReview: Review;
  lowestReview: Review;

  constructor(private restaurantsService: RestaurantsService, private reviewsService: ReviewsService,
              private route: ActivatedRoute, private auth: AuthService) { }

  deleteReview(id: number) {
    this.reviewsService.delete(id).subscribe(_ => this.getRestaurant());
  }

  ngOnInit() {
    this.restaurantId = this.route.snapshot.params.id;
    this.getRestaurant();
  }

  private getLowestReview() {
    return this.restaurant.reviews.reduce(
      (lowestReview, review) => (!lowestReview || review.rating < lowestReview.rating) ? review : lowestReview,
      null
    );
  }

  private getRestaurant() {
    this.route.paramMap.pipe(
      switchMap(params => {
        this.restaurantId = +params.get('id');
        return this.restaurantsService.get(this.restaurantId);
      })
    ).subscribe(response => {
      this.restaurant = response;
      this.topReview = this.getTopReview();
      this.lowestReview = this.getLowestReview();
    });
  }

  private getTopReview() {
    return this.restaurant.reviews.reduce(
      (topReview, review) => (!topReview || review.rating > topReview.rating) ? review : topReview,
      null
    );
  }
}
