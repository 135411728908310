import { Component, OnInit, ViewChild } from '@angular/core';
import { RestaurantsService } from './restaurants.service';
import { Restaurant } from './restaurant.model';
import { AuthService } from '../auth/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Reply } from './reply.model';
import { RepliesService } from './replies.service';
import { Review } from './review.model';
import { ReviewsService } from './reviews.service';

@Component({
  selector: 'app-restaurants',
  templateUrl: './restaurants.component.html',
  styleUrls: ['./restaurants.component.css']
})
export class RestaurantsComponent implements OnInit {
  @ViewChild('editRestaurantModal') editRestaurantModal;
  @ViewChild('addRestaurantModal') addRestaurantModal;
  @ViewChild('newReplyModal') newReplyModal;
  @ViewChild('newReviewModal') newReviewModal;
  newReview: Review;
  restaurants: Restaurant[];
  ratingsFilter = 0;
  editingRestaurant: Restaurant;
  newRestaurant: Restaurant;
  newReply: Reply;

  constructor(private auth: AuthService, private restaurantsService: RestaurantsService,
              private reviewsService: ReviewsService, private repliesService: RepliesService,
              private modalService: NgbModal) { }

  addReview(restaurantId: number) {
    this.resetNewReview(restaurantId);
    this.modalService.open(this.newReviewModal);
  }

  addReply(reviewId: number) {
    this.resetNewReply(reviewId);
    this.modalService.open(this.newReplyModal);
  }

  deleteReply(id: number) {
    this.repliesService.delete(id).subscribe(_ => this.getRestaurants());
  }

  addRestaurant() {
    this.newRestaurant = {
      name: '',
      user_id: this.auth.getUser().id
    };
    this.modalService.open(this.addRestaurantModal);
  }

  deleteRestaurant(id: number) {
    this.restaurantsService.delete(id).subscribe(_ => this.getRestaurants());
  }

  editRestaurant(id: number) {
    this.restaurantsService.get(id)
      .subscribe(restaurant => {
        this.editingRestaurant = restaurant;
        this.modalService.open(this.editRestaurantModal);
      });
  }

  filter() {
    this.getRestaurants();
  }

  ngOnInit() {
    this.getRestaurants();
  }

  onSubmitReply() {
    this.repliesService.post(this.newReply)
      .subscribe(_ => {
        this.getRestaurants();
        this.modalService.dismissAll();
      });
  }

  onSubmitRestaurant() {
    this.restaurantsService.post(this.newRestaurant)
      .subscribe(_ => {
        this.getRestaurants();
        this.modalService.dismissAll();
      });
  }

  onSubmitRestaurantUpdate() {
    this.restaurantsService.put(this.editingRestaurant)
      .subscribe(_ => {
        this.getRestaurants();
        this.modalService.dismissAll();
      });
  }

  onSubmitReview() {
    this.reviewsService.post(this.newReview)
      .subscribe(_ => {
        this.getRestaurants();
        this.modalService.dismissAll();
      });
  }

  setRating(newRating: number) {
    if (this.newReview.rating !== +newRating) {
      this.newReview.rating = newRating;
    } else {
      this.newReview.rating = 0;
    }
  }

  unansweredReviews(restaurant: Restaurant) {
    return restaurant.reviews.filter(review => !review.reply);
  }

  private getRestaurants() {
    this.restaurantsService.getAll()
      .subscribe(response => {
        this.restaurants = response.filter(
          restaurant => this.ratingsFilter === 0 || restaurant.rating >= this.ratingsFilter
        );
      });
  }

  private resetNewReply(reviewId: number) {
    this.newReply = {
      reply: '',
      review_id: reviewId,
      user_id: this.auth.getUser().id
    };
  }

  private resetNewReview(restaurantId: number) {
    this.newReview = {
      comment: '',
      rating: 0,
      date: new Date(),
      restaurant_id: restaurantId,
      user_id: this.auth.getUser().id
    };
  }
}
