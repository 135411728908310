import { Injectable } from '@angular/core';
import { ApiClientService } from '../api-client.service';
import { Reply } from './reply.model';

@Injectable({
  providedIn: 'root'
})
export class RepliesService {
  constructor(private apiClient: ApiClientService) { }

  post(newReply: Reply) {
    return this.apiClient.post<Reply>('replies', newReply);
  }

  delete(id: number) {
    return this.apiClient.delete('replies', id);
  }
}
