import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoginCredentials } from '../login/login-credentials.model';
import { LoginResponse } from './login-response.model';
import { User } from '../users/user.model';
import { UserType } from '../users/user-type';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private token: string;
  private user: User;

  constructor(private http: HttpClient) {
    this.token = localStorage.getItem('token');
    this.user = JSON.parse(localStorage.getItem('user'));
  }

  getToken() {
    return this.token;
  }

  getUser() {
    return this.user;
  }

  isAdmin() {
    return this.user && this.user.userType === UserType.ADMIN;
  }

  isOwner() {
    return this.user && this.user.userType === UserType.OWNER;
  }

  isRegular() {
    return this.user && this.user.userType === UserType.REGULAR;
  }

  isLoggedIn() {
    return !!this.token;
  }

  login(loginCredentials: LoginCredentials) {
    return new Promise((resolve, reject) => {
      this.http.post<LoginResponse>(
        'https://toptal-api.andu.codes/auth/login',
        loginCredentials,
        { 'headers':
          { 'Accept': 'application/json' }
        }
      )
        .subscribe(
          response => {
            this.token = response.token;
            localStorage.setItem('token', this.token);
            this.user = response.user;
            localStorage.setItem('user', JSON.stringify(response.user));
            resolve(true);
          },
          error => {
            reject(error.error.message);
          }
        );
    });
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    this.token = null;
    this.user = null;

    return Promise.resolve(true);
  }
}
