import { Component, OnInit, ViewChild } from '@angular/core';
import { User } from './user.model';
import { UsersService } from './users.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  @ViewChild('editUserModal') editUserModal;
  @ViewChild('addUserModal') addUserModal;
  users: User[];
  editingUser: User;
  newUser: User;

  constructor(private auth: AuthService, private usersService: UsersService, private modalService: NgbModal) { }

  deleteUser(id: number) {
    this.usersService.delete(id).subscribe(_ => this.getUsers());
  }

  addUser() {
    this.newUser = {
      email: '',
      password: '',
      user_type_id: null
    };
    this.modalService.open(this.addUserModal);
  }

  editUser(id: number) {
    this.usersService.get(id).subscribe(user => {
      this.editingUser = user;
      this.modalService.open(this.editUserModal);
    });
  }

  ngOnInit() {
    this.getUsers();
  }

  onSubmitUser() {
    this.usersService.post(this.newUser)
      .subscribe(_ => {
        this.getUsers();
        this.modalService.dismissAll();
      });
  }

  onSubmitUserUpdate() {
    this.usersService.put(this.editingUser)
      .subscribe(_ => {
        this.getUsers();
        this.modalService.dismissAll();
      });
  }

  private getUsers() {
    this.usersService.getAll()
      .subscribe(response => {
        this.users = response;
      });
  }

}
