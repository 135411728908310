import { Injectable } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiClientService {

  constructor(private auth: AuthService, private http: HttpClient) { }

  delete(path: string, id: number) {
    return this.http.delete(
      `https://toptal-api.andu.codes/${path}/${id}`,
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: `Bearer ${this.auth.getToken()}`
        })
      }
    );
  }

  get<Model>(path: string, id: number) {
    return this.http.get<Model>(
      `https://toptal-api.andu.codes/${path}/${id}`,
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: `Bearer ${this.auth.getToken()}`
        })
      }
    );
  }

  getAll<ResponseType>(path: string) {
    return this.http.get<ResponseType>(
      `https://toptal-api.andu.codes/${path}`,
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: `Bearer ${this.auth.getToken()}`
        })
      }
    );
  }

  post<Model>(path: string, data: Model) {
    return this.http.post<Model>(
      `https://toptal-api.andu.codes/${path}`,
      data,
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: `Bearer ${this.auth.getToken()}`,
          'Content-Type': 'application/json'
        })
      }
    );
  }

  put<Model>(path: string, data: Model, id: number) {
    return this.http.put<Model>(
      `https://toptal-api.andu.codes/${path}/${id}`,
      data,
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: `Bearer ${this.auth.getToken()}`,
          'Content-Type': 'application/json'
        })
      }
    );
  }
}
